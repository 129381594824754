// redirect to Punchout website along with posting data to that website by creating an html form
var postB2bData = function postB2bData(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci) {
  var form = document.createElement('FORM');
  form.method = 'POST';
  form.enctype = 'application/x-www-form-urlencoded';
  form.style.display = 'none';
  form.action = postBackURL;

  if (isSapOci) {
    var inputVals = JSON.parse(punchoutResponseMsg);
    Object.keys(inputVals).forEach(function (key) {
      var input = document.createElement('INPUT');
      input.name = key;
      input.value = inputVals[key];
      form.appendChild(input);
    });
  } else {
    var input = document.createElement('INPUT');
    input.type = 'hidden';

    if (isDecodeNeeded) {
      //from bytestream, to percent-encoding, to original string.
      input.value = decodeURIComponent(atob(punchoutResponseMsg).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      input.name = 'cXML-urlencoded';
    } else {
      input.value = punchoutResponseMsg;
      input.name = 'cXML-base64';
    }

    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
};

export default postB2bData;